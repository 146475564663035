@mixin mt-zorro-form-edit($theme) {
  .footer {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  .ant-advanced-search-form {
    padding: 24px;
    background: map-get($map: $theme, $key: formEditBg);
    border: 1px solid #d9d9d9;
    border-radius: 6px;
  }

  .search-result-list {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
  }

  [nz-form-label] {
    overflow: visible;
  }

  .ant-advanced-search-form .ant-form-item {
    margin-bottom: 24px;
    width: 100%;
  }
}
