@import '~@imerge22/platform/theming/platform';
@import '~@imerge22/platform/theming/zorro';
@import '~@imerge22/encoding/theming/encoding';
@import '~@imerge22/common/style';
@import '~@imerge22/spectrum/theming/theming';
@import 'assets/font/iconfont.css';

html,
body {
  height: 100%;
  background-color: #f7fbfd;
}

router-outlet {
  display: none;
}

body {
  margin: 0;
  font-family: 'Microsoft Yahei', 'Helvetica Neue', sans-serif;
  font-size: 14px;
}

$mt-bg-color: #40a9ff;

.logo-bar-bg {
  background: linear-gradient(90deg, #e6ebef, $mt-bg-color);
  // background: linear-gradient(90deg, #e6ebef, #003a6f);
}

$mt-theme: (
  bgColor: rgba(0, 0, 0, 0.04),
  formBg: #fbfbfb,
  formEditBg: #fff,
  common: (
    color: #212121,
    bg: white,
    primary: #1890ff,
    border: (
      common: #e0e0e0,
      primary: #2c2e43,
    ),
  ),
  button: (
    colors: (
      warn: (
        front: white,
        back: orange,
      ),
    ),
  ),
  card: (
    title-bg: #f5f5f5,
  ),
  tabs: (
    bar-bg: white,
    noLinkBar: true,
    tab: (
      bg: #eef0f5,
      color: #333,
      active: (
        bg: #fff,
        color: $mt-bg-color,
      ),
      hover: (
        color: $mt-bg-color,
      ),
    ),
  ),
  drawer: (
    header: (
      bg: #455a64,
      contrast: white,
      height: 55px,
    ),
  ),
  table: (
    header: #f5f5f5,
    hover: #f5f5f5,
    // selected: #cfcfcf,
    selected: #add7fe,
    odd: white,
    // even: #f5f5f5,
    even: white,
  ),
  tree: (
    matched: red,
  ),
  layout: (
    header: (
      background: #fff,
      height: 60px,
    ),
    sider: (
      background: #fff,
      triger: transparentize(white, 0.9),
      submenu: (
        background: #fff,
        // active: linear-gradient(90deg, #6ab0db, #32618b),
      ),
    ),
  ),
);

@include mt-platform($mt-theme);
@include mt-zorro($mt-theme);
@include mt-spectrum($mt-theme);

.main-tab {
  > .ant-tabs-nav {
    > .ant-tabs-nav-wrap {
      border: none;
      height: 40px;
      > .ant-tabs-nav-list {
        > .ant-tabs-tab {
          height: 40px;
          border: none;
          padding: 0 20px;
          margin: 0 1px 0 0;
          padding: 0 16px;
          line-height: 38px;
          border-radius: 4px 4px 0 0;
          background-color: #fff;
          color: #979797;
          border-bottom: 1px solid #f0f0f0;

          &.ant-tabs-tab-active {
            background-color: #E6F6FF;
            border-radius: 4px 4px 0 0;
            border-right: 1px solid #eef0f5;
            border-left: 1px solid #eef0f5;
            border-bottom: 2px solid #64A0EF;
            .mt-tab-title {
              color: $mt-bg-color;
              > i {
                margin-left: 10px;
                color: #979797;
              }
            }
          }

          .mt-tab-title {
            color: #979797;
            font-family: '微软雅黑 Bold', '微软雅黑 Regular', 微软雅黑, sans-serif;
            &:hover {
              color: $mt-bg-color;
              > i {
                color: #979797;
              }
            }
          }
        }
      }
    }
  }
  > .ant-tabs-content-holder {
    border-radius: 4px;
    background: #ffffff;
  }
}

.uccs-fill {
  width: 100% !important;
}
.uccs-fill-2 .uccs-fill {
  width: 50% !important;
}
.uccs-fill-3 .uccs-fill {
  width: 33.33% !important;
}

.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 5px 0;
}

.mt-tree-search {
  padding: 2px !important;
  height: 37px !important;
}
.ant-tabs-extra-content {
  line-height: 37px;
}
.ant-card .ant-card-head {
  height: 37px;
}
.ant-tabs.ant-tabs-top > .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 16px;
}

.ant-tabs.ant-tabs-top > .ant-tabs-nav {
  height: auto;
}

.mt-maxmodal-wrap {
  top: 55px;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #E6F6FF;
}
.ant-modal.mt-maximum {
  width: 100% !important;
  height: 100%;
  top: 0;
}

.mt-tab-title {
  user-select: none;
}
/* 滚动条优化 start */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: #f6f6f6;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover {
  background: #747474;
}
::-webkit-scrollbar-corner {
  background: #f6f6f6;
}
/* 火狐浏览器优化 */
* {
  scrollbar-color: #c1c1c1 #f7f7f9; /* 滑块颜色  滚动条背景颜色 */
  scrollbar-width: thin; /* 滚动条宽度有三种：thin、auto、none */
}
/* 滚动条优化 end */

.mt-tap-link {
  > .ant-tabs-nav {
    > .ant-tabs-nav-wrap {
      > .ant-tabs-nav-list {
        .ant-tabs-ink-bar {
          position: absolute !important;
          background: #1890ff !important;
          pointer-events: none !important;
        }
        .ant-tabs-tab {
          background-color: #fff;
          border-bottom: 1px solid #f0f0f0;
          font-family: '微软雅黑 Bold', '微软雅黑 Regular', 微软雅黑, sans-serif;
        }
      }
    }
  }
}

.mt-tap-default {
  > .ant-tabs-nav {
    height: 49px !important;
    > .ant-tabs-nav-wrap {
      > .ant-tabs-nav-list {
        .ant-tabs-tab {
          background-color: #ffffff;
        }
      }
    }
  }
}
.mt-home {
  .mt-home-dashboad {
    padding: 0 10px 10px 0;
    width: auto;
    .mt-tap-default {
      .ant-tabs-nav {
        flex-direction: row-reverse;
      }
      .ant-tabs-nav-wrap {
        flex-direction: row-reverse;
      }
    }

    .ant-tabs.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
      padding: 0 16px;
    }
  }

  .mt-home-quick-entry{
    padding: 0 0 10px 10px;
    width: 280px;
  }
}
.mt-upload-input {
  display: none !important;
}
.mt-root-column {
  flex-flow: column nowrap;
}
.mt-root-row {
  flex-flow: row nowrap;
}
.mt-root-row,
.mt-root-column {
  display: flex;
  margin: 20px 20px 10px;
  height: calc(100% - 30px);
  form.mt-form-query {
    .ant-input,
    nz-select {
      height: 32px;
      width: 180px;
      font-size: 12px;
    }
    nz-form-control {
      margin-right: 15px;
    }
    margin-bottom: 20px;

    .ant-picker-input input {
      width: 90px;
    }
  }
}

.mt-btns {
  button {
    height: 30px;
    padding: 0 15px;
    font-size: 12px;
    border-radius: 3px;
  }
  button:not(:last-child) {
    margin-right: 10px;
  }
}

.ant-table table {
  td a {
    font-size: 12px;
  }
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 2px !important;
}
