.mt-nopadding {
  padding: 0;
}

.mt-center {
  text-align: center !important;
}

.mt-left {
  text-align: left !important;
}

.mt-right {
  text-align: right !important;
}

.mt-mr-5 {
  margin-right: 5px;
}
.mt-mr-10 {
  margin-right: 10px;
}

.mt-danger {
  color: red;
}

.mt-success {
  color: green;
}

.mt-text-wrap {
  word-break: break-word !important;
  white-space: normal !important;
}

mt-router-outlet {
  display: none;
}

.mt-grid-borderd {
  border: 1px solid #ddd;
  grid-gap: 1px;
  background-color: #ddd;
  > * {
    background-color: white;
  }
}
