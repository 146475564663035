@mixin mt-zorro-layout($theme) {
  .ant-layout-header {
    padding: 0;
  }

  @if map_has_key($theme, layout) {
    $layout: map_get($theme, layout);

    @if map_has_key($layout, header) {
      $header: map_get($layout, header);
      @if map_has_key($header, background) {
        .ant-layout-header {
          background: map_get($header, background);
        }
      }
      @if map_has_key($header, height) {
        .ant-layout-header {
          height: map_get($header, height);
          line-height: map_get($header, height);
        }
      }
    }

    @if map_has_key($layout, sider) {
      $sider: map_get($layout, sider);

      @if map_has_key($sider, background) {
        .ant-layout-sider {
          background: map_get($sider, background);
        }
        .ant-menu.ant-menu-dark,
        .ant-menu-dark .ant-menu-sub {
          background: map_get($sider, background);
        }
      }

      @if map_has_key($sider, triger) {
        .ant-layout-sider-trigger {
          background: map_get($sider, triger);
          color: #333;
          box-shadow: 0px -2px 20px 0px rgba(217, 236, 255, 0.5);
        }
      }

      @if map_has_key($sider, submenu) {
        $submenu: map_get($sider, submenu);

        @if map_has_key($submenu, background) {
          .ant-menu-dark .ant-menu-inline.ant-menu-sub {
            background: map_get($submenu, background);
          }
          .ant-menu.ant-menu-dark .ant-menu-item-selected {
            background: map_get($submenu, background);
            color: map_get($theme, mtColor);
          }
          .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
            background: map_get($submenu, background);
          }
        }
      }
    }
  }
}
