@mixin mt-zorro-form-search($theme) {
  .ant-advanced-search-form {
    padding: 24px;
    background: map-get($map: $theme, $key: formBg);
    border: 1px solid #d9d9d9;
  }

  .search-result-list {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
  }

  [nz-form-label] {
    overflow: visible;
  }

  .ant-advanced-search-form .ant-form-item {
    margin-bottom: 24px;
    width: 100%;
  }
  form .ant-input-group .ant-select {
    width: 100% !important;
  }
}
