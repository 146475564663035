@import './funcs';

@mixin mt-ctrls($theme) {
  $borderColor: rgba-to-rgb(
    map-get(
      $map: map-get(map-get($theme, common), border),
      $key: common,
    )
  );

  $bgColor: rgba-to-rgb(map-get(map-get($theme, common), bg));

  .mt-ctrl-grid {
    display: grid;
    border: 1px solid $borderColor;
    grid-gap: 1px;
    background-color: $borderColor;
    > * {
      background-color: $bgColor;
      padding: 4px 8px;
      min-height: 40px;
    }
    .mt-ctrl-grid {
      border: none;
    }
  }

  .mt-ctrl-grid {
    .mt-ctrl-grid {
      padding: 0;
      background-color: $borderColor;
    }
    mt-ctrl-multi-reference-collapse,
    mt-ctrl-static-collapse {
      padding: 0;
      > .ant-collapse {
        border-width: 0;
      }
    }
  }

  mt-ctrl-multi-reference-collapse,
  mt-ctrl-static-collapse {
    > .ant-collapse {
      border-radius: 0;
      > .ant-collapse-item {
        > .ant-collapse-header {
          display: grid;
          grid-template-columns: min-content 1fr auto;
          justify-items: start;
          align-items: center;
          min-height: 36px;
          padding: 6px 8px 4px 40px;
          background: #f5f5f5;
          > *:last-child {
            justify-self: end;
          }
        }
        > .ant-collapse-content {
          > .ant-collapse-content-box {
            padding: 0;
          }
        }
      }
      &.ant-collapse-icon-position-right {
        > .ant-collapse-item {
          > .ant-collapse-header {
            padding: 4px 40px 4px 8px;
          }
        }
      }
    }
  }
  mt-ctrl-multi-reference-collapse,
  mt-ctrl-static-collapse {
    .ant-collapse {
      .ant-collapse {
        margin: 10px;
        border-width: 1px;
      }
    }
    mt-ctrl-prop-table-dict-tabset {
      padding: 10px;
    }
    mt-static-tab {
      margin: 10px;
    }
  }

  .mt-ctrl .ant-tabs-tabpane > mt-ctrl-multi-reference-collapse > .ant-collapse {
    margin: 0;
  }

  .mt-ctrl .ant-collapse {
    background-color: white;
  }

  .mt-ctrl .ant-collapse-item {
    margin-top: 10px;
    border-top: 1px solid #d9d9d9;
    &:first-child {
      border-top: none;
      margin-top: 0;
    }
  }

  mt-static-tab,
  mt-ctrl-prop-table-dict-tabset {
    .ant-tabs-tab {
      color: rgba(0, 0, 0, 0.65);
      background-color: $borderColor;
    }
    .ant-tabs > .ant-tabs-content > .ant-tabs-tabpane {
      display: block;
    }
    nz-tabset {
      nz-tabs-nav {
        mt-ctrl-prop-name {
          background: rgba(0, 0, 0, 0) !important;
        }
      }
    }
    .ant-tabs.ant-tabs-top > .ant-tabs-content {
      height: auto;
    }
  }
  mt-ctrl-grid {
    .ant-checkbox-disabled + span {
      color: rgba(0, 0, 0, 0.65);
    }
    .ant-radio-disabled + span {
      color: rgba(0, 0, 0, 0.65);
    }
    .ant-tabs.ant-tabs-top > .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
      height: auto;
      border-radius: 10px 10px 0px 0px;
    }
    mt-ctrl-prop-table-dict-tabset {
      .ant-tabs.ant-tabs-top > .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
        width: 90px;
      }
    }
  }
  .mt-ctrl {
    .mt-ctrl {
      display: grid;
      align-items: center;
      height: 100%;
    }
    .ant-collapse-content-box {
      overflow-x: auto;
      overflow-y: visible;
    }
    .ant-tabs {
      .ant-tabs-tab {
        .mt-ctrl {
          height: auto;
        }
      }
      > .ant-tabs-content {
        height: auto;
        > .ant-tabs-tabpane > * {
          height: auto;
        }
      }
    }

    mt-ctrl-alert.mt-alert-no-permission {
      > nz-alert > div {
        background-color: #fff;
        text-decoration: underline;
        font-style: italic;
        color: #9c9c9c;
        span {
          color: #9c9c9c;
        }
      }
    }

    // 无权限样式设置
    mt-ctrl-bool,
    mt-ctrl-date,
    mt-ctrl-dict,
    mt-ctrl-multi-reference-collapse,
    mt-ctrl-number,
    mt-prop-table,
    mt-ctrl-prop-table-dict-tabset,
    mt-ctrl-relation,
    mt-ctrl-string {
      position: relative;
    }
    .mt-no-permission-icon {
      position: absolute;
      top: 0px;
      right: 5px;
      color: #ff9900;
      font-size: 17px;
    }
  }

  .mt-select-no-empty .ant-select-item-empty {
    display: none;
  }
  .mt-ctrl-grid {
    .mt-desc-tips {
      font-style: italic;
      color: #c0c0c0;
    }
  }
}
