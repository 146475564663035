@import './funcs';

@mixin mt-form-editor($theme) {
  $border: map-get(map-get($theme, common), border);

  $borderColor: map-get(
    $map: $border,
    $key: common,
  );

  mt-form-editor {
    > div {
      border-color: $borderColor;
      > nz-resize-handle > .slide-wrap > .slide {
        border-color: $borderColor;
        background-color: rgba-to-rgb(map-get($theme, bgColor));
      }
    }
  }

  mt-ctrl-alert {
    > nz-alert > .ant-alert {
      border: none;
      border-radius: 0;
    }
  }
}
