.ant-modal {
  > .ant-modal-content {
    > .ant-modal-body {
      overflow: auto;
      font-size: 12px !important;
    }
  }
  &.mt-maximum {
    width: calc(100% - 40px) !important;
    height: calc(100% - 40px);
    top: 20px;
    padding: 0;
    > .ant-modal-content {
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      border-radius: 0;
      > .ant-modal-header,
      > .ant-modal-footer {
        flex: 0 0 auto;
      }
      > .ant-modal-header {
        padding: 8px 38px 8px 8px;
      }
      .ant-modal-close {
        > .ant-modal-close-x {
          height: 38px;
          width: 38px;
          line-height: 38px;
        }
      }
      > .ant-modal-body {
        height: 100px;
        padding: 8px;
        flex: 1 1 100px;
        overflow: auto;
        > :first-child {
          height: 100%;
        }
      }
    }
  }
  &.mt-notitle {
    > .ant-modal-content {
      > .ant-modal-close {
        > .ant-modal-close-x {
          height: 24px;
          width: 24px;
          line-height: 24px;
        }
      }
    }
  }

  .ant-modal-footer {
    height: 54px !important;
  }
  .ant-btn-round {
    border-radius: 5px;
    border: 1px solid #C0C0C0;
    font-size: 12px;
    height: 32px;
  }
  .ant-form-item-label>label , .ant-input, .ant-upload,.ant-checkbox, .ant-checkbox-wrapper {
    font-size: 12px !important;
  }

  .mt-select-role .ant-table-wrapper{
    max-height: 200px;
  }
}

.ant-table, .ant-table.ant-table-middle, .ant-table.ant-table-small {
  font-size: 12px !important;
  .ant-empty{
    font-size: 12px !important;
  }
}
