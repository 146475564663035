@font-face {
  font-family: "iconfont"; /* Project id 2461345 */
  src: url('/assets/font/iconfont.woff2?t=1626078490366') format('woff2'),
       url('/assets/font/iconfont.woff?t=1626078490366') format('woff'),
       url('/assets/font/iconfont.ttf?t=1626078490366') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconUpxiangshang7:before {
  content: "\e6db";
}

.iconDownxiangxia7:before {
  content: "\e722";
}

