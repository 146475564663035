@mixin mt-zorro-drawer($theme) {
  $drawer: map-get(
    $map: $theme,
    $key: drawer
  );
  $header: map-get(
    $map: $drawer,
    $key: header
  );
  $border: map-get(
    $map:
      map-get(
        $map: $theme,
        $key: common
      ),
    $key: border
  );

  .ant-drawer-wrapper-body {
    overflow: hidden;
  }

  .ant-drawer-header {
    border: none;
    border-radius: 0;
    height: map-get($map: $header, $key: height);
    background-color: map-get($map: $header, $key: bg);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .ant-drawer-title,
  .ant-drawer-close {
    color: map-get($map: $header, $key: contrast);
  }

  .ant-drawer-close {
    height: map-get($map: $header, $key: height);
    width: map-get($map: $header, $key: height);
  }

  .ant-drawer-body {
    height: calc(100% - #{map-get($header, height)});
    overflow: auto;
    position: relative;
  }

  .ant-drawer-content-wrapper.mt-nopadding {
    .ant-drawer-body {
      padding: 0;
    }
  }

  .ant-drawer-content-wrapper.mt-with-footer {
    .ant-drawer-body {
      padding-bottom: 60px;

      > :first-child:last-child {
        min-height: 100%;
      }

      .mt-drawer-footer {
        position: sticky;
        height: 60px;
        bottom: -60px;
        top: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
        padding: 0 24px;
        margin-left: -24px;
        margin-right: -24px;
        margin-bottom: -60px;
        background-color: white;
        border-top: 1px solid map-get($map: $border, $key: common);

        > * {
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    &.mt-nopadding {
      .ant-drawer-body {
        .mt-drawer-footer {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}
