@import 'core';

@mixin mt-platform($theme) {
  $common: ();
  $primary: #1890ff;
  $border: ();
  $borderCommonColor: #e0e0e0;

  @if map_has_key($theme, common) {
    $common: map_get($theme, common);
  }
  @if map_has_key($common, border) {
    $border: map_get($common, border);
  }
  @if map_has_key($border, common) {
    $borderCommonColor: map_get($border, common);
  }
  @if map_has_key($common, primary) {
    $primary: map_get($common, primary);
  }

  .mt-link:not([disabled]) {
    color: $primary;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .mt-border-color {
    border-color: $borderCommonColor;
  }
}
