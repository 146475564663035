@import '../funcs.scss';

@mixin mt-zorro-table($theme) {
  $border: map-get(map-get($theme, common), border);
  $table: map-get($theme, table);
  $header-bg: map-get(
    $map: $table,
    $key: header,
  );
  $even-bg: rgba-to-rgb(rgba($header-bg, 0.2));

  table.mt-table {
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: map-get($border, common);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: map-get($border, common);
    min-width: 100%;
    th {
      background-color: $header-bg;
    }
    th,
    td {
      border-top-style: solid;
      border-top-width: 1px;
      border-top-color: map-get($border, common);
      border-left-style: solid;
      border-left-width: 1px;
      border-left-color: map-get($border, common);
      padding: 4px 8px;
      // width: 1px;
      word-break: keep-all;
      white-space: nowrap;
      height: 40px;
      &.mt-fill {
        width: 100%;
      }
      &.mt-min {
        width: 1px;
      }
    }
    &.mt-fill-2 {
      th,
      td {
        &.mt-fill {
          width: 50%;
        }
      }
    }
    &.mt-fill-3 {
      th,
      td {
        &.mt-fill {
          width: 33.3%;
        }
      }
    }
    &.mt-fill-4 {
      th,
      td {
        &.mt-fill {
          width: 25%;
        }
      }
    }
    &.mt-fill-5 {
      th,
      td {
        &.mt-fill {
          width: 20%;
        }
      }
    }
  }

  table.mt-evened {
    > tbody {
      > tr:nth-of-type(even) {
        > td {
          background-color: $even-bg;
        }
      }
    }
  }

  nz-table {
    width: 100%;
    height: 100%;
    > nz-spin {
      width: 100%;
      height: 100%;
      > div.ant-spin-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        overflow: hidden;
        background-color: #ffffff;
        > nz-pagination.ant-table-pagination.ant-pagination {
          font-size: 12px !important;
          flex: 0 0 auto;
          margin: 8px 0;
          padding: 0 8px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-end;
          > .ant-pagination-options {
            > nz-select {
              margin-right: 0;
              font-size: 12px !important;
            }
          }
        }
        > div.ant-table {
          flex: 1 1 100px;
          border-radius: unset;
          display: flex;
          flex-direction: column;
          > .ant-table-container {
            flex: 1 1 100px;
            width: 100%;
            height: 100%;
            display: flex;
            overflow: auto;
            flex-direction: column;
            > div.ant-table-content {
              min-width: 100%;
              height: 100%;
              overflow: auto;
              margin: 0;
              > table {
                // width: auto !important;  // 表格无法撑满该样式导致，屏蔽测试系统界面是否合理
                table-layout: auto !important;
                > thead {
                  > tr {
                    > th {
                      font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
                      font-weight: bold;
                      background-color: #f1f6fa;
                      width: 1px;
                      //min-width: 0 !important;
                      word-break: keep-all;
                      white-space: nowrap;
                      position: sticky;
                      top: 0;
                      z-index: 9;
                      padding: 4px 8px;
                      height: 41px;
                      &.mt-fill {
                        width: 100%;
                      }
                      &.mt-sticky {
                        position: sticky;
                        z-index: 10;
                        left: 0;
                      }
                      &.mt-sticky-left-shadow {
                        box-shadow: 6px 0 6px 0px rgba(0, 0, 0, 0.05);
                      }
                      &.mt-sticky-right-shadow {
                        box-shadow: -6px 0 6px 0px rgba(0, 0, 0, 0.05);
                        border-left-width: 1px;
                        border-left-style: solid;
                        border-left-color: map-get($border, common);
                      }
                      &.ant-table-column-sort {
                        background-color: $header-bg;
                      }
                    }
                    &.mt-filter-row {
                      > th.mt-filter {
                        padding: 4px;
                        .ant-table-header-column {
                          width: 100%;
                        }
                      }
                    }
                  }
                }
                > tbody {
                  > tr {
                    > td {
                      word-break: keep-all;
                      white-space: nowrap;
                      padding: 4px 8px;
                      height: 40px;
                      &.mt-sticky {
                        position: sticky;
                        z-index: 2;
                        left: 0;
                      }
                      &.mt-sticky-left-shadow {
                        box-shadow: 6px 0 6px 0px rgba(0, 0, 0, 0.05);
                      }
                      &.mt-sticky-right-shadow {
                        box-shadow: -6px 0 6px 0px rgba(0, 0, 0, 0.05);
                        border-left-width: 1px;
                        border-left-style: solid;
                        border-left-color: map-get($border, common);
                      }
                    }
                    &:nth-of-type(odd):not(.mt-selected):not(:hover) {
                      > td {
                        background-color: map_get($table, odd);
                      }
                    }
                    &:nth-of-type(even):not(.mt-selected):not(:hover) {
                      > td {
                        background-color: map_get($table, even);
                      }
                    }
                    &.mt-selected {
                      > td {
                        background-color: map_get($table, selected) !important;
                      }
                    }
                  }
                }
              }
            }
            > div.ant-table-placeholder {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          > .ant-table-footer {
            flex: 0 0 auto;
          }
        }
      }
    }
  }

  nz-table.mt-scroll-left
    > nz-spin
    > div.ant-spin-container
    > div.ant-table
    > div.ant-table-content
    > div.ant-table-body
    > table {
    > thead > tr > th,
    > tbody > tr > td {
      &.mt-sticky-left-shadow {
        box-shadow: none;
      }
    }
  }

  nz-table.mt-scroll-right
    > nz-spin
    > div.ant-spin-container
    > div.ant-table
    > div.ant-table-content
    > div.ant-table-body
    > table {
    > thead > tr > th,
    > tbody > tr > td {
      &.mt-sticky-right-shadow {
        box-shadow: none;
        border-left: none;
      }
    }
  }

  nz-table.mt-fill-2
    > nz-spin
    > div.ant-spin-container
    > div.ant-table
    > div.ant-table-content
    > div.ant-table-body
    > table
    > thead
    > tr
    > th.mt-fill {
    width: 50%;
  }

  nz-table.mt-fill-3
    > nz-spin
    > div.ant-spin-container
    > div.ant-table
    > div.ant-table-content
    > div.ant-table-body
    > table
    > thead
    > tr
    > th.mt-fill {
    width: 33%;
  }

  nz-table.mt-fill-4
    > nz-spin
    > div.ant-spin-container
    > div.ant-table
    > div.ant-table-content
    > div.ant-table-body
    > table
    > thead
    > tr
    > th.mt-fill {
    width: 25%;
  }

  nz-table.mt-fill-5
    > nz-spin
    > div.ant-spin-container
    > div.ant-table
    > div.ant-table-content
    > div.ant-table-body
    > table
    > thead
    > tr
    > th.mt-fill {
    width: 20%;
  }
}

// 表格可调节列宽样式
.mt-table-move {
  // 撑宽
  .ant-table-thead > tr > th .ant-table-filter-icon,
  .ant-table-thead > tr > th .anticon-filter {
    right: 5px;
  }

  nz-spin > div.ant-spin-container > div.ant-table > div.ant-table-content > .ant-table-body {
    table {
      // > thead > tr > th,
      > tbody > tr > td {
        white-space: normal;
      }
    }
  }
}
