@mixin mt-zorro-collapse($theme) {
  $collapse: ();

  @if map_has_key($theme, collapse) {
    $collapse: map_get($theme, collapse);
  }

  .ant-collapse {
    @if map_has_key($collapse, border-radius) {
      border-radius: map_get($collapse, border-radius);
      > .ant-collapse-item:last-child {
        border-radius: map_get($collapse, border-radius);
      }
    }
    @if map_has_key($collapse, content-padding) {
      > .ant-collapse-item {
        > .ant-collapse-content {
          .ant-collapse-content-box {
            padding: map_get($collapse, content-padding);
          }
        }
      }
    }
  }

  nz-collapse.mt-nopadding {
    .ant-collapse {
      > .ant-collapse-item {
        > .ant-collapse-content {
          .ant-collapse-content-box {
            padding: 0;
          }
        }
      }
    }
  }
}
