@mixin mt-zorro-card($theme) {
  .ant-card {
    display: flex;
    flex-direction: column;
    border-radius: unset;
    .ant-card-head {
      flex: 0 0 auto;
      margin-bottom: 0;
      border-bottom-color: map-get(
        $map: map-get($map: map-get($theme, common), $key: border),
        $key: primary
      );
      background-color: map-get($map: map-get($theme, card), $key: title-bg);
      .ant-card-head-title {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .ant-card-body {
      flex: 1 1 1px;
      overflow: auto;
    }
    &.mt-nopadding {
      .ant-card-body {
        padding: 0;
      }
    }
    &.mt-overflow-hidden {
      .ant-card-body {
        overflow: hidden;
      }
    }
    &.mt-autoheight {
      display: block;
    }
    &.mt-body-flex {
      .ant-card-body {
        display: flex;
      }
    }
    &.mt-body-flex-column {
      .ant-card-body {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
