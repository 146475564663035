@mixin mt-zorro-timeline($theme) {
  .ant-timeline {
    > nz-timeline-item:last-child {
      > .ant-timeline-item {
        > .ant-timeline-item-tail {
          display: none;
        }
      }
    }
  }
}
