@import './form-editor';
@import './ctrls';

@mixin mt-spectrum($theme) {
  @include mt-form-editor($theme);
  @include mt-ctrls($theme);
}

.ant-tabs-tabpane {
  & > mt-panel-form,
  & > mt-panel-properties {
    & > * {
      border-left-width: 0;
    }
  }
}
