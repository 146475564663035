@mixin mt-zorro-tree($theme) {
  .ant-tree {
    &.ant-tree-icon-hide .ant-tree-treenode-loading .ant-tree-iconEle {
      display: inline-block;
    }
    .ant-tree-switcher {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-tree-switcher,
    .ant-tree-iconEle {
      > i {
        > svg,
        > img {
          height: 16px;
          width: 16px;
        }
      }
    }
    .ant-tree-title {
      input {
        padding: 0 5px;
        height: 24px;
        width: calc(100% - 24px);
      }
    }

    mark {
      font-weight: bold;
      padding: 0;
      color: map-get(map-get($theme, tree), matched);
    }

    .ant-tree-child-tree {
      overflow: visible;
    }
  }
}
