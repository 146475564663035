@import '../funcs';

@mixin mt-zorro-button($theme) {
  $button: ();
  $colors: ();

  @if map_has_key($theme, button) {
    $button: map_get($theme, button);
  }

  @if map_has_key($button, colors) {
    $colors: map_get($button, colors);
  }

  @each $color-name, $color-value in $colors {
    $back: map_get($color-value, back);
    $hover: rgba-to-rgb(rgba($back, 0.75));
    $front: map_get($color-value, front);
    .ant-btn-#{$color-name} {
      color: $front;
      background-color: $back;
      border-color: $back;
      &:focus,
      &:hover {
        color: $front;
        background-color: $hover;
        border-color: $hover;
      }
    }

    .ant-btn-group .ant-btn-#{$color-name} + .ant-btn-#{$color-name},
    .ant-btn-group .ant-btn-#{$color-name}:last-child:not(:first-child) {
      border-left-color: $back;
      &:focus,
      &:hover {
        border-left-color: $hover;
      }
    }
  }

  .ant-btn-group {
    &.mt-sharp-round {
      > .ant-btn.ant-btn-sm {
        &:first-child {
          border-top-left-radius: 24px;
          border-bottom-left-radius: 24px;
        }
        &:last-child {
          border-top-right-radius: 24px;
          border-bottom-right-radius: 24px;
        }
      }
    }
  }
}
