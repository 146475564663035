@mixin mt-zorro-panel($theme) {
  .mt-detail-item-wrapper {
    height: 100%;
    width: 100%;
    background-color: map-get($map: $theme, $key: bgColor);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 8px;
  }

  .mt-detail-item-wrapper > .mt-detail-item-label {
    font-weight: 700;
    text-align: right;
    margin-right: 0.75em;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 150px;
  }

  .mt-detail-item-wrapper > .mt-detail-item-text {
    color: #455a64;
    flex: 1 1 100%;
    word-break: break-all;
  }
  .ant-list-bordered .ant-list-item {
    padding-right: 1px;
    padding-left: 1px;
  }
  .ant-list-grid .ant-list-item {
    display: block;
    max-width: 100%;
    margin-bottom: 16px;
    padding-top: 1px;
    padding-bottom: 1px;
    border-bottom: none;
  }
}
