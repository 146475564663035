@mixin mt-zorro-tabs($theme) {
  .mt-tab-title {
    padding: 12px 16px;
    margin: -12px -16px;
    > i.anticon:last-child {
      margin-left: 2px;
    }
  }

  .ant-tabs {
    height: 100%;
    border-color: map-get(map-get(map-get($theme, common), border), common);
    > .ant-tabs-nav {
      margin: 0;
      background: map-get(map-get($theme, tabs), bar-bg);
      flex: 0 0 auto;
    }
    &.ant-tabs-top {
      display: flex;
      flex-direction: column;
      > .ant-tabs-nav {
        height: 35px;
        .ant-tabs-nav-wrap {
          margin-bottom: 0;
        }
        .ant-tabs-nav-scroll {
          height: 35px;
        }
        .ant-tabs-nav-list .ant-tabs-tab {
          margin-right: 1px;
          padding: 12px 16px;
          height: 35px;
          i.anticon:last-child {
            margin-right: 0;
          }
        }
      }
      > .ant-tabs-content {
        height: 100px;
      }
    }
    &.ant-tabs-left {
      display: flex;
      flex-direction: row;
      > .ant-tabs-nav {
        width: 36px;
        min-width: 36px;
        .ant-tabs-nav-wrap {
          margin-right: 0;
        }
        > .ant-tabs-nav-operations {
          > .ant-tabs-nav-more {
            padding: 0;
          }
        }
        .ant-tabs-nav-list {
          height: 100%;
          width: 36px;
          .ant-tabs-tab {
            margin: 0;
            padding: 16px 8px;
            writing-mode: vertical-rl;
            height: auto;
            width: 100%;
            i.anticon:last-child {
              margin-right: 0;
            }
            .ant-badge {
              margin-top: 3px;
              writing-mode: horizontal-tb;
            }
          }
        }
      }
      > .ant-tabs-content-holder {
        height: 100%;
        width: 1px;
        padding: 0;
        > .ant-tabs-content {
          &.ant-tabs-left-content {
            border-left: none;
          }
          > .ant-tabs-tabpane-inactive {
            height: 0;
          }
          > .ant-tabs-tabpane-active {
            height: 100%;
            padding: 0;
          }
        }
      }
    }
    > .ant-tabs-content-holder {
      > .ant-tabs-content {
        height: 100%;
        > .ant-tabs-tabpane {
          overflow: auto;
          background: map-get(map-get($theme, common), bg);
          display: flex;
          flex-flow: column;
          > * {
            flex: 1 1 1px;
            height: 1px;
          }
        }
      }
    }
    &.mt-no-content {
      > .ant-tabs-content {
        display: none;
      }
    }
  }

  @if map_has_key($theme, tabs) {
    $tabs: map_get($theme, tabs);

    @if map_has_key($tabs, noLinkBar) {
      $noLinkBar: map_get($tabs, noLinkBar);
      @if $noLinkBar {
        .ant-tabs-nav-list > .ant-tabs-ink-bar {
          background-color: transparent;
        }
      }
    }

    @if map_has_key($tabs, tab) {
      $tab: map_get($tabs, tab);

      @if map_has_key($tab, bg) {
        .ant-tabs-tab {
          background: map_get($tab, bg);
        }
      }
      @if map_has_key($tab, color) {
        .ant-tabs-tab {
          color: map_get($tab, color);
        }
      }

      @if map_has_key($tab, hover) {
        $hover: map_get($tab, hover);

        @if map_has_key($hover, color) {
          .ant-tabs-tab:hover {
            color: map_get($hover, color);
          }
        }
      }

      @if map_has_key($tab, active) {
        $active: map_get($tab, active);

        @if map_has_key($active, bg) {
          .ant-tabs-tab-active,
          .ant-tabs-nav .ant-tabs-tab-active {
            background: map_get($active, bg);
          }
        }
        @if map_has_key($active, color) {
          .ant-tabs-tab-active,
          .ant-tabs-nav .ant-tabs-tab-active,
          .ant-tabs-tab-active:hover,
          .ant-tabs-nav .ant-tabs-tab-active:hover {
            .ant-tabs-tab-btn {
              font-family: '微软雅黑 Bold', '微软雅黑 Regular', 微软雅黑, sans-serif;
              color: map_get($active, color);
            }
          }
        }
      }
    }
  }
}
